var timeout;
var delay_fadeout;
var before_pos;
var fix_bottom_obj = document.getElementsByClassName('fix_bottom')[0];
var move_top_btn = document.getElementById("movetop_btn");
var bottom_ad_container = document.getElementById("bottom_ad_container");
var bottom_ad_del_btn = document.getElementById("bottom_ad_del_btn");

if( document.getElementById("bottom_menu_signin_ver") != null) {
    var selectBoxPrefecture = document.querySelector('#bottom_menu_prefecture_id');
    var selectBoxArea = document.querySelector('#bottom_menu_area_id');
    var selectBoxRock = document.querySelector("#bottom_menu_rock_id");
    
    selectBoxPrefecture.addEventListener('change', (event) => {
        var prefecture_id = selectBoxPrefecture.options[selectBoxPrefecture.selectedIndex].value;
        
        window.selectBoxRefresh('/prefectures/' + prefecture_id + '/areas.json', selectBoxArea);
        selectBoxRock.setAttribute('disabled', 'disabled');
        selectBoxRock.selectedIndex = 0;
        
        selectBoxArea.removeAttribute('disabled');
    
    });
    
    selectBoxArea.addEventListener('change', (event) => {
        var area_id = selectBoxArea.options[selectBoxArea.selectedIndex].value;
        
        window.selectBoxRefresh('/areas/' + area_id + '/rocks.json', selectBoxRock);
        selectBoxRock.removeAttribute('disabled');
    });

    document.getElementById("bottom_menu_selectsearch_submt_btn").addEventListener('click', ()=>{
      document.getElementById("bottom_menu_search_spinner").classList.remove("nonedisp");
    });
    
    
    document.getElementById('bottom_menu_map_current_position_btn').addEventListener('click', ()=>{
      var latobj = document.getElementById("bottom_menu_lat_form");
      var lngobj = document.getElementById("bottom_menu_lng_form");
      var getform = document.getElementById("bottom_menu_reporteditform");
      
      window.nearListGet(latobj, lngobj, getform, "bottom_menu_search_spinner");
    });
    
    document.getElementById('bottom_menu_map_current_position_rock_btn').addEventListener('click', ()=>{
      var latobj = document.getElementById("bottom_menu_lat_form2");
      var lngobj = document.getElementById("bottom_menu_lng_form2");
      var getform = document.getElementById("bottom_menu_reporteditrockform");
      
      window.nearListGet(latobj, lngobj, getform, "bottom_menu_search_spinner");
    });
    
    document.getElementById("bottom_menu_pref_search_btn").addEventListener('click', (e)=>{
      document.getElementById("bottom_menu_pref_search_container").classList.replace("nonedisp", "fadein");
    });
}

if( document.getElementById("bottom_menu_nearrockbtn") != null) {
  document.getElementById("bottom_menu_nearrockbtn").addEventListener('click', (e)=>{
    e.preventDefault();
    window.nearObjectUrlOpen(e.currentTarget.href);
  });
}

move_top_btn.addEventListener('click', (e)=>{
  e.preventDefault();
  window.scrollTo({ top: window.screen.height * (-1), behavior: "smooth" }); 
});

window.onscroll = function(){
    
    if ( timeout ) return;
    
    timeout = setTimeout( () => {
        timeout = 0;

        // スクロールされたピクセル数
        var current_pos;
        current_pos = document.scrollingElement.scrollTop;
        
        if( current_pos < before_pos ) {
          bottom_menu_fadein();
          
          clearTimeout(delay_fadeout);
          delay_fadeout = setTimeout(delay_menu_fadeout, 5000);
          
        } else {
          // バウンススクロール対応でスクロール数が一定以上の時のみフェードアウト
          if ( (current_pos - before_pos) > 100) {
            bottom_menu_fadeout();
          }
        }
        before_pos = current_pos;
    }, 200);
};

if(bottom_ad_del_btn!=null){
  bottom_ad_del_btn.addEventListener("click",()=>{
    bottom_ad_container.classList.add("nonedisp");
  });
}

function bottom_menu_fadein(){
  fix_bottom_obj.classList.remove('nonedisp');
  fix_bottom_obj.classList.add('fadein');
  fix_bottom_obj.classList.remove('fadeout');
  move_top_btn.classList.add('fadeout');
  move_top_btn.classList.remove('fadein');
  if(bottom_ad_container!=null){
    bottom_ad_container.classList.remove('fadein');
    bottom_ad_container.classList.add('fadeout');
  }
}

function bottom_menu_fadeout(){
  fix_bottom_obj.classList.remove('fadein');
  fix_bottom_obj.classList.add('fadeout');
  if(window.scrollY>5000){
    move_top_btn.classList.remove('nonedisp');
    move_top_btn.classList.remove('fadeout');
    move_top_btn.classList.add('fadein');
  }
  if(bottom_ad_container!=null){
    bottom_ad_container.classList.remove('fadeout');
    bottom_ad_container.classList.add('fadein');
  }
}

function delay_menu_fadeout(){
  fix_bottom_obj.classList.remove('fadein');
  fix_bottom_obj.classList.add('fadeout');
  move_top_btn.classList.remove('fadein');
  move_top_btn.classList.add('fadeout');
  if(bottom_ad_container!=null){
    bottom_ad_container.classList.remove('fadeout');
    bottom_ad_container.classList.add('fadein');
  }
}

